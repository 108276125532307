<template>
  <div>
    <b-row>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.ownBalance).toLocaleString()" />
          <span v-text="$t('own_balance')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.totalBalance).toLocaleString()" />
          <span v-text="$t('total_balance')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.todayNoOfBet).toLocaleString()" />
          <span v-text="$t('today_no_of_bet')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.todayNoOfPlayer).toLocaleString()" />
          <span v-text="$t('today_no_of_player')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.todayBet).toLocaleString()" />
          <span v-text="$t('today_bet')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.todayRolling).toLocaleString()" />
          <span v-text="$t('today_rolling')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.todayWinLoss).toLocaleString()" />
          <span v-text="$t('today_win_loss')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(onlineUsers.length).toLocaleString()" />
          <span v-text="$t('online_users')" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { io } from 'socket.io-client'

import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'

import Account from '@/models/Account'
import { EventBus } from '../../eventbus'

export default {
  name: 'Dashboard',
  components: {
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      socket: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      onlineUsers: [],
      report: {
        ownBalance: 0,
        totalBalance: 0,
        todayNoOfBet: 0,
        todayNoOfPlayer: 0,
        todayBet: 0,
        todayRolling: 0,
        todayWinLoss: 0,
      },
    }
  },
  mounted() {
    if (this.userData.role === 'company') {
      this.socket = io(`${process.env.VUE_APP_MIX_SOCKET_URL}/agent`, {
        secure: true,
        query: {
          companyId: this.userData.id,
        },
      })
    } else if (this.userData.role === 'agent') {
      this.socket = io(`${process.env.VUE_APP_MIX_SOCKET_URL}/agent`, {
        secure: true,
        query: {
          parentId: this.userData.id,
        },
      })
    } else if (this.userData.role === 'distributor') {
      this.socket = io(`${process.env.VUE_APP_MIX_SOCKET_URL}/agent`, {
        secure: true,
        query: {
          distributorId: this.userData.id,
        },
      })
    } else if (this.userData.role === 'admin') {
      this.socket = io(`${process.env.VUE_APP_MIX_SOCKET_URL}/agent`, {
        secure: true,
        query: {},
      })
    }
    if (this.userData.role === 'company' || this.userData.role === 'agent' || this.userData.role === 'distributor' || this.userData.role === 'admin') {
      this.socket.on('online_user_list', data => {
        this.onlineUsers = data
      })
    }
    this.loadData()
  },
  methods: {
    async loadData() {
      const response = await Account.dashboard()
      this.report = response.data.report
      EventBus.$emit('getMyUpdatedUserData') // Todo: Need to refactor
    },
  },
}
</script>

<style>
</style>
